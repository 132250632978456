<template>
  <v-app>
   <div class="row">
     <div class="col-xl-2">
       <router-link to="/teachers" class="card-xl-stretch mb-xl-8 bg-primary card hoverable" v-if="checkIsAccessible('user', 'list')">
         <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-users font-white"></i>
                </span>
           <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_students || 0 }}</div>
           <div class="text-inverse-primary fw-semobold fs-7">Total active students</div>
         </div>
       </router-link>
     </div>
     <div class="col-xl-2">
       <router-link to="/teachers" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
         <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-heart font-white"></i>
                </span>
           <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_wishlist || 0 }}</div>
           <div class="text-inverse-primary fw-semobold fs-7">Total wishlist</div>
         </div>
       </router-link>
     </div>
     <div class="col-xl-2">
       <router-link to="/library/lost-book" class="card-xl-stretch mb-xl-8 bg-primary card hoverable" v-if="checkIsAccessible('library-book', 'list')">
         <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-unlink font-white"></i>
                </span>
           <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_lost_books || 0 }}</div>
           <div class="text-inverse-primary fw-semobold fs-7">Total lost books</div>
         </div>
       </router-link>
     </div>
     <div class="col-xl-2">
       <router-link to="/teachers" class="card-xl-stretch mb-xl-8 bg-dark card hoverable" v-if="checkIsAccessible('library-book', 'list')">
         <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-book font-white"></i>
                </span>
           <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_unique_books || 0 }}</div>
           <div class="text-inverse-primary fw-semobold fs-7">Total unique books</div>
         </div>
       </router-link>
     </div>
     <div class="col-xl-2">
       <router-link to="/teachers" class="card-xl-stretch mb-xl-8 bg-primary card hoverable" v-if="checkIsAccessible('library-book', 'list')">
         <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-book-open font-white"></i>
                </span>
           <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_accession_books || 0 }}</div>
           <div class="text-inverse-primary fw-semobold fs-7">Total books (Accession)</div>
         </div>
       </router-link>
     </div>
   </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book=new BookService();
export default {
  data(){
    return{
      loading:false,
      stats:{
        total_active_students:0,
        total_wishlist:0,
        total_lost_books:0,
        total_accession_books:0,
        total_unique_books:0,
      },
    }
  },
  methods:{
    getLibraryStats(){
      this.loading=true;
        book
        .getLibraryStat()
        .then(response => {
            this.stats=response.data;
        }).catch((err) => {

      }).finally(()=>{
            this.loading=false;
          });
    },
  },
  mounted() {
    this.getLibraryStats();
  }
}
</script>