<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <v-row>
              <v-col cols="4" >
                <v-select dense v-on:keyup.enter="getLibraryBookStatsForGraph()" @change="getLibraryBookStatsForGraph" outlined label="Filter by" :items="filter_types" v-model="search.filter_type" item-value="value" item-text="text">
                </v-select>
              </v-col>
              <v-col cols="4" v-if="search.filter_type=='custom_date'">
                <v-menu
                    v-model="start_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field  dense outlined
                        v-model="search.start_date"
                        label="Start date"
                                   prepend-inner-icon="mdi-calendar"
                                   readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker  v-model="search.start_date" @input="changeStartDateMenu"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" v-if="search.filter_type=='custom_date'">
                <v-menu
                    v-model="end_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined
                        v-model="search.end_date"
                        label="End date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="search.end_date"  @input="changeEndDateMenu"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" class="text-right" v-if="search.filter_type=='custom_date'">
                <v-btn
                    @click="getLibraryBookStatsForGraph()"
                    class="btn btn-primary btn-search "
                    :loading="loading"
                >
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="border-graph" v-if="!loading">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book =new BookService();
export default {
  data(){
    return{
      start_date_menu:false,
      end_date_menu:false,
      filter_types:[
        {text:'Today',value:'today'},
        {text:'Yesterday',value:'yesterday'},
        {text:'This week',value:'this_week'},
        {text:'This month',value:'this_month'},
        {text:'Custom date',value:'custom_date'},
      ],
      search:{
        filter_type:'today',
        start_date:null,
        end_date:new Date().toISOString().substr(0, 10),
      },
      loading:false,
      series: [
        {
          data: []
        }
      ],
      chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors:  ['#00FF00','#EAC117'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: ['#0C090A','#0C090A'],
            fontSize: '12px'
          }
        }
      }
    },
    }
  },
  methods:{
    changeStartDateMenu(){
      this.start_date_menu=false;

    },
    changeEndDateMenu(){
      this.end_date_menu=false;

    },
    getLibraryBookStatsForGraph(){
      this.loading=true;
      book
          .getLibraryBookStatForGraph(this.search)
          .then((response) => {
            let items=response.data.library_book_stats;
            let labels=[];
            let values=[];
              items.forEach(function (item) {
                labels.push(item.label);

                values.push(item.value);
            });
            this.chartOptions.xaxis.categories=labels[0];
            this.series[0].data=values[0];
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          });
    }
  },
  mounted() {
    this.getLibraryBookStatsForGraph();
  }
}
</script>