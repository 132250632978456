<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <library-counter></library-counter>
              </div>
              <div class="col-md-5">
                <top-library-book-issued-list></top-library-book-issued-list>
              </div>
              <div class="col-md-7">
                <library-book-stats></library-book-stats>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </v-app>
</template>
<script>
import LibraryBookStats from "@/view/pages/library-book/stats/LibraryBookStats.vue";
import LibraryCounter from "@/view/pages/library-book/stats/LibraryCounter.vue";
import TopLibraryBookIssuedList from "@/view/pages/library-book/stats/TopLibraryBookIssuedList.vue";
export default {
  components: {
    LibraryCounter,
    TopLibraryBookIssuedList,
    LibraryBookStats,
  }
}
</script>