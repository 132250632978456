<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="6" >
                  <v-select dense v-on:keyup.enter="getTopLibraryBookIssuedList()" @change="getTopLibraryBookIssuedList" outlined label="Months" :items="months" v-model="search.month" item-value="value" item-text="text">
                  </v-select>
                </v-col>
                <v-col cols="6" >
                  <v-select dense v-on:keyup.enter="getTopLibraryBookIssuedList()" @change="getTopLibraryBookIssuedList" outlined label="Year" :items="academic_years" v-model="search.year" item-value="year" item-text="year">
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <div class="text-center h6">
              <p class="font-weight-bold">Most issued book list</p>
            </div>
            <v-list-item v-show="top_book_lists.length > 0"
                v-for="(item,index) in top_book_lists"
                :key="index"
            >
              <v-list-item-avatar>
                <v-icon
                    class="grey lighten-1"
                    dark
                >
                  mdi-book
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title >
                  <span class="font-weight-medium h6">
                    {{item.title}}
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <div class="text-secondary ml-1">
                    <span class="font-weight-bold">Author : </span> <span class="font-weight-medium">{{item.author}}</span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-badge
                    color="error"
                    :content="item.total_issued_count"
                    inline
                ></v-badge>

              </v-list-item-action>
            </v-list-item>
            <div class="text-center" v-show="top_book_lists.length==0">
              <span class="font-weight-medium">Data not available</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
const academicYear=new AcademicYearService();
const book=new BookService();
export default {
  data(){
    return{
      loading:false,
      top_book_lists: [],
      months:[
        {text:'January',value:1},
        {text:'February',value:2},
        {text:'March',value:3},
        {text:'April',value:4},
        {text:'May',value:5},
        {text:'June',value:6},
        {text:'July',value:7},
        {text:'August',value:8},
        {text:'September',value:9},
        {text:'October',value:10},
        {text:'November',value:11},
        {text:'December',value:12},
      ],
      academic_years:[],
      search:{
        month:'',
        year:'',
      },
    }
  },
 methods: {
   getTopLibraryBookIssuedList(){
    this.loading=true;
     book
         .getTopIssuedBookStat(this.search)
         .then((response) => {
            this.top_book_lists=response.data.top_book_lists;

         })
         .catch((err) => {

         })
         .finally(() => {
           this.loading=false;
         });
   },
   getAllAcademicYear(){
     academicYear
         .all()
         .then((response) => {
           this.academic_years=response.data;
            this.search.year=this.academic_years[0].year;
            if(this.search.year){

             this.getTopLibraryBookIssuedList();
           }
         })
         .catch((err) => {

         })
         .finally(() => {
           this.loading=false;
         });
   },
 },
  mounted() {
    this.getAllAcademicYear();
    const current = new Date();
    this.search.month=current.getMonth()+1


  }

}
</script>